<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Identify the <b>element</b> based on the provided description of its location in the
        periodic table. Give the <b>atomic symbol</b> for the element, not its name.
      </p>

      <v-simple-table style="width: 250px">
        <thead>
          <tr>
            <th style="width: 70%; font-size: 14px">
              <stemble-latex content="$\text{Location}$" />
            </th>
            <th style="width: 30%; font-size: 14px">
              <stemble-latex content="$\text{Element}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex
                :content="`$\\text{Period }${element1Period}\\text{, Group }${element1Group}$`"
              />
            </td>
            <td>
              <chemical-symbol-input
                v-model="inputs.input1"
                class="pt-2 pb-2 centered-input"
                group-size="sm"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex
                :content="`$\\text{Period }${element2Period}\\text{, Group }${element2Group}$`"
              />
            </td>
            <td>
              <chemical-symbol-input
                v-model="inputs.input2"
                class="pt-2 pb-2 centered-input"
                group-size="sm"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex
                :content="`$\\text{Period }${element3Period}\\text{, Group }${element3Group}$`"
              />
            </td>
            <td>
              <chemical-symbol-input
                v-model="inputs.input3"
                class="pt-2 pb-2 centered-input"
                group-size="sm"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalSymbolInput from '../inputs/ChemicalSymbolInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question10',
  components: {
    ChemicalSymbolInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
    };
  },
  computed: {
    chemicalElement1() {
      return this.taskState.getValueBySymbol('element1').content;
    },
    element1Period() {
      return this.chemicalElement1.period;
    },
    element1Group() {
      return this.chemicalElement1.group;
    },
    chemicalElement2() {
      return this.taskState.getValueBySymbol('element2').content;
    },
    element2Period() {
      return this.chemicalElement2.period;
    },
    element2Group() {
      return this.chemicalElement2.group;
    },
    chemicalElement3() {
      return this.taskState.getValueBySymbol('element3').content;
    },
    element3Period() {
      return this.chemicalElement3.period;
    },
    element3Group() {
      return this.chemicalElement3.group;
    },
  },
};
</script>
